<script setup>
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'

import HomePreview from '@/components/customize/HomePreview.vue'
import FormFieldInput from '@/components/FormFieldInput.vue'
import FormFieldError from '@/components/FormFieldError.vue'

const { isLoading, selectedPortal } = storeToRefs(usePortalsStore())

</script>

<template>
  <div class="row">
    <div class="col-4">
      <fieldset class="mb-5">
        <h3>Background</h3>
        <p>
          Please select the colors used to set the theme across your site.
        </p>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Main Portal</span>
            <span> (includes modals)</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$backgroundColor"
            :disabled="isLoading"
            placeholder="#FFFFFF"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$backgroundColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Header</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$headerBackgroundColor"
            :disabled="isLoading"
            placeholder="#FFFFFF"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$headerBackgroundColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Footer</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$footerBackgroundColor"
            :disabled="isLoading"
            placeholder="#FFFFFF"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$footerBackgroundColor" />
        </div>
      </fieldset>
      <fieldset class="mb-2">
        <h3>Supporting Element Colors</h3>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Divider Bar</span></label>
            <p>A line element used to separate content blocks on a page including header and footer</p>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$dividerBarColor"
            :disabled="isLoading"
            placeholder="#373737"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$dividerBarColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Form field/check box background</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$formFieldBackgroundColor"
            :disabled="isLoading"
            placeholder="#FFFFFF"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$formFieldBackgroundColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Form field/check box border</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$formFieldBorderColor"
            :disabled="isLoading"
            placeholder="#373737"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$formFieldBorderColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Tool tip background</span></label>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$tooltipBackgroundColor"
            :disabled="isLoading"
            placeholder="#373737"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$tooltipBackgroundColor" />
        </div>
        <div class="form-group mt-3">
          <label class="form-label">
            <span class="bold-text">Info container background</span></label>
            <p>These containers group certain information being presented to the shoppers (ie return method selection)</p>
          <FormFieldInput
            type="text"
            class="form-control"
            :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$infoContainerBackgroundColor"
            :disabled="isLoading"
            placeholder="#FFFFFF"
          />
          <FormFieldError :fieldModel="selectedPortal.fields.customize.globalStyles.colors.$infoContainerBackgroundColor" />
        </div>
      </fieldset>
    </div>
    <div class="col-8">
      <HomePreview />
    </div>
  </div>
</template>

<style scoped>
fieldset {
    width: 95%;
}

.bold-text {
  font-weight: 600;
}

.form-control {
  width: 7em;
}
</style>