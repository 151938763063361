import { object, string } from 'yup'
import { HEX_COLOR_REGEX } from '@/constants'

const colorSchema = string()
  .nullable()
  .transform(value => (!value || value.length === 0 ? null : value))
  .matches(HEX_COLOR_REGEX, 'The color is invalid')

export default object({
  background_color: colorSchema.label('Background color'),
  header_background_color: colorSchema.label('Header background color'),
  footer_background_color: colorSchema.label('Footer background color'),
  divider_bar_color: colorSchema.label('Divider bar color'),
  form_field_background_color: colorSchema.label('Form field background color'),
  form_field_border_color: colorSchema.label('Form field border color'),
  tooltip_background_color: colorSchema.label('Tool tip background color'),
  info_container_background_color: colorSchema.label('Info container background color')
})