<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePortalsStore } from '@/stores/portals'
import { getFileUrl } from '@/utils/file'
import BrowserPreview from '@/components/customize/BrowserPreview.vue'
import MainLayoutPreview from '@/components/customize/MainLayoutPreview.vue'
import InfoTooltip from '@/components/InfoTooltip.vue'
import ButtonPreview from '@/components/customize/ButtonPreview.vue'

defineProps({
  howItWorksHighlighted: Boolean
})

const { selectedPortal } = storeToRefs(usePortalsStore())

const mainImageUrl =  computed(() => {
  const { fields } = selectedPortal.value
  if (fields.customize.content.orderLookup.$image.inputValue) {
    return getFileUrl(fields.customize.content.orderLookup.$image.inputValue)
  }
  return null
})

const bodyFontFamily = computed(() => {
  const { fields } = selectedPortal.value
  return fields.customize.globalStyles.fonts.$bodyBrandFontFamily.inputValue
    ? fields.customize.globalStyles.fonts.$bodyBrandFontFamily.inputValue
    : fields.customize.globalStyles.fonts.$bodyWebFontFamily.inputValue
})

const bodyColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.fonts.$bodyColor.inputValue
})

const mainHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.customize.globalStyles.fonts.$mainHeaderBrandFontFamily.inputValue) {
    return fields.customize.globalStyles.fonts.$mainHeaderBrandFontFamily.inputValue
  }

  return fields.customize.globalStyles.fonts.$mainHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const subHeaderFontFamily = computed(() => {
  const { fields } = selectedPortal.value

  if (fields.customize.globalStyles.fonts.$subHeaderBrandFontFamily.inputValue) {
    return fields.customize.globalStyles.fonts.$subHeaderBrandFontFamily.inputValue
  }

  return fields.customize.globalStyles.fonts.$subHeaderWebFontFamily.inputValue || bodyFontFamily.value
})

const headerColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.fonts.$headerColor.inputValue || bodyColor.value
})

const buttonColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.buttons.$primaryActiveBackgroundColor.inputValue
})

const headerText = computed(() => (
  selectedPortal.value.fields.customize.content.orderLookup.$header.inputValue
))

const inputLabelText = computed(() => (
  selectedPortal.value.fields.customize.content.orderLookup.$inputLabel.inputValue
))

const inputPlaceholderText = computed(() => (
  selectedPortal.value.fields.customize.content.orderLookup.$inputPlaceholder.inputValue
))

const inputLabelTextZipcode = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.ZIP_CODE_LOOKUP?.ZIP_CODE_TEXT_FIELD_TITLE
))

const inputPlaceholderTextZipCode = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.ZIP_CODE_LOOKUP?.ENTER_ZIP_CODE_PLACEHOLDER
))

const buttonText = computed(() => (
  selectedPortal.value.fields.customize.content.orderLookup.$buttonText.inputValue
))

const returnAGiftText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.ORDER_LOOKUP?.RETURN_A_GIFT
))

const returnAGiftTitleText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.ORDER_LOOKUP?.GIFT_RETURN_TITLE
))

const howItWorksText = computed(() => (
  selectedPortal.value?.values?.settings?.strings?.ORDER_LOOKUP?.HOW_IT_WORKS
))

const orderIdHelpText = computed(() => (
  selectedPortal.value.fields.customize.content.orderIdHelpModal.$helpQuestion.inputValue
))

const isOrderIdHelpEnabled = computed(() => {
  return selectedPortal.value.fields.customize.content.orderIdHelpModal.$enabled.inputValue
})

const backgroundColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.colors.$backgroundColor.inputValue
})

const dividerBarColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.colors.$dividerBarColor.inputValue
})

const formFieldBackgroundColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.colors.$formFieldBackgroundColor.inputValue
})

const formFieldBorderColor = computed(() => {
  return selectedPortal.value.fields.customize.globalStyles.colors.$formFieldBorderColor.inputValue
})

</script>

<template>
  <BrowserPreview>
    <MainLayoutPreview>
      <div
        class="home-preview d-flex flex-fill"
        :style="{
          'font-family': bodyFontFamily,
          'color': bodyColor,
          'background-color': backgroundColor
        }"
      >
        <div class="w-50 d-flex align-items-center justify-content-center">
          <div class="form-container">
            <div class="d-flex align-items-center justify-content-between">
              <div
                class="form-header flex-fill me-1"
                :style="{
                  'font-family': mainHeaderFontFamily,
                  'color': headerColor
                }"
              >
                {{ headerText }}
              </div>
              <div
                class="how-it-works-link"
                :class="{ 'highlighted': howItWorksHighlighted }"
              >
                <ButtonPreview
                  :label="howItWorksText"
                  type="tertiary"
                  state="active"
                  padding="0px"
                />
              </div>
            </div>
            <div
              class="d-flex align-items-center flex-wrap"
            >
              <div
                class="form-sub-header me-2"
                :style="{
                'font-family': subHeaderFontFamily,
                'color': headerColor
              }"
              >
                {{ inputLabelText }}
              </div>
              <div v-if="isOrderIdHelpEnabled">
                <ButtonPreview
                  class="order-id-help-link"
                  :label="orderIdHelpText"
                  type="tertiary"
                  state="active"
                  padding="0px"
                />
              </div>
            </div>
            <div class="form-input" 
              :style="{
                'background-color': formFieldBackgroundColor,
                'border-color': formFieldBorderColor
              }">
              {{ inputPlaceholderText }}
            </div>
            <div class="form-sub-header mb-1"
              :style="{
                'font-family': subHeaderFontFamily,
                'color': headerColor
              }"
              style="display: inline-flex"
            >
              {{ inputLabelTextZipcode }}
              <InfoTooltip :color="buttonColor" style="margin-left: 6px">
              </InfoTooltip>
            </div>
            <div class="form-input"
              :style="{
                'background-color': formFieldBackgroundColor,
                'border-color': formFieldBorderColor
              }">
              {{ inputPlaceholderTextZipCode }}
            </div>
            <div class="mt-4">
              <ButtonPreview
                :label="buttonText"
                type="primary"
                state="active"
              />
            </div>
            <hr class="mt-4 mb-4" :style="{
              'border-color': dividerBarColor
        }">
            <div
              class="form-gift-container"
            >
              <div>
                {{ returnAGiftTitleText }}
              </div>
              <div class="mt-2">
                <ButtonPreview
                  :label="returnAGiftText"
                  type="secondary"
                  state="active"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-50">
          <img
            class="w-auto img-fluid float-end hero-image"
            v-if="mainImageUrl"
            :src="mainImageUrl"
            alt=""
          >     
        </div>
      </div>
    </MainLayoutPreview>
  </BrowserPreview>
</template>

<style lang="scss" scoped>
.home-preview {
  min-height: 324px;

  .form-container {
    min-width: 262px;
    max-width: 375px;
    padding-bottom: 20px;

    .form-header {
      font-size: 24px;
      font-weight: 900;
      margin: 12px 0;
    }

    .form-sub-header {
      font-size: 12px;
      font-weight: 900;
    }

    .form-input {
      font-size: 16px;
      width: 100%;
      padding: 12px;
      border: 1px solid #A8A8A8;
      color: #a7a7a7;
      margin-bottom: 12px;
    }

    .order-id-help-link {
      padding: 0px;
      margin: 0px;
    }

    .how-it-works-link {
      border: 3px solid transparent;
      padding: 5px;

      &.highlighted {
        border-color: #FDC014
      }
    }

    .form-gift-container {
      margin-top: 16px;
      padding: 0px 70px;
      font-size: 18px;
      font-weight: 900;
      text-align: center;
    }
  }
}
</style>
