export default Object.freeze({
  identifier: 'identifier',
  policies: {
    timeWindows: {
      $returnWindowEnabled: 'settings.policies.return_window_enabled',
      $returnWindowDays: 'settings.policies.return_window_days',
      $refundTimingEstimate: 'settings.policies.refund_timing_estimate',
      $dropOffWindowDays: 'settings.policies.drop_off_window_days',
      $holidayReturnWindows: 'settings.policies.holiday_return_windows'
    },
    $returnReasons: 'settings.policies.return_reasons'
  },
  customize: {
    globalStyles: {
      logoAssets: {
        $favicon: 'settings.styles.logo_assets.favicon',
        $mainLogo: 'settings.styles.logo_assets.main_logo'
      },
      fonts: {
        $brandFontAssets: 'settings.styles.fonts.brand_font_assets',
        $mainHeaderBrandFontFamily: 'settings.styles.fonts.main_header_brand_font_family',
        $mainHeaderWebFontFamily: 'settings.styles.fonts.main_header_web_font_family',
        $subHeaderBrandFontFamily: 'settings.styles.fonts.sub_header_brand_font_family',
        $subHeaderWebFontFamily: 'settings.styles.fonts.sub_header_web_font_family',
        $headerColor: 'settings.styles.fonts.header_color',
        $bodyBrandFontFamily: 'settings.styles.fonts.body_brand_font_family',
        $bodyWebFontFamily: 'settings.styles.fonts.body_web_font_family',
        $bodyColor: 'settings.styles.fonts.body_color'
      },
      buttons: {
        $roundedEnabled: 'settings.styles.buttons.button_rounded_enabled',
        $radius: 'settings.styles.buttons.button_radius',
        $textUppercaseEnabled: 'settings.styles.buttons.button_text_uppercase_enabled',
        $primaryActiveBackgroundColor: 'settings.styles.buttons.button_primary_active_background_color',
        $primaryActiveTextColor: 'settings.styles.buttons.button_primary_active_text_color',
        $primaryHoverBackgroundColor: 'settings.styles.buttons.button_primary_hover_background_color',
        $primaryHoverTextColor: 'settings.styles.buttons.button_primary_hover_text_color',
        $primaryDisabledBackgroundColor: 'settings.styles.buttons.button_primary_disabled_background_color',
        $primaryDisabledTextColor: 'settings.styles.buttons.button_primary_disabled_text_color',
        $secondaryActiveBackgroundColor: 'settings.styles.buttons.button_secondary_active_background_color',
        $secondaryActiveBorderColor: 'settings.styles.buttons.button_secondary_active_border_color',
        $secondaryActiveTextColor: 'settings.styles.buttons.button_secondary_active_text_color',
        $secondaryHoverBackgroundColor: 'settings.styles.buttons.button_secondary_hover_background_color',
        $secondaryHoverBorderColor: 'settings.styles.buttons.button_secondary_hover_border_color',
        $secondaryHoverTextColor: 'settings.styles.buttons.button_secondary_hover_text_color',
        $secondaryDisabledBackgroundColor: 'settings.styles.buttons.button_secondary_disabled_background_color',
        $secondaryDisabledBorderColor: 'settings.styles.buttons.button_secondary_disabled_border_color',
        $secondaryDisabledTextColor: 'settings.styles.buttons.button_secondary_disabled_text_color',
        $tertiaryActiveTextColor: 'settings.styles.buttons.button_tertiary_active_text_color',
        $tertiaryHoverTextColor: 'settings.styles.buttons.button_tertiary_hover_text_color',
        $tertiaryDisabledTextColor: 'settings.styles.buttons.button_tertiary_disabled_text_color'
      },
      colors: {
        $backgroundColor: 'settings.styles.colors.background_color',
        $headerBackgroundColor: 'settings.styles.colors.header_background_color',
        $footerBackgroundColor: 'settings.styles.colors.footer_background_color',
        $dividerBarColor: 'settings.styles.colors.divider_bar_color',
        $formFieldBorderColor: 'settings.styles.colors.form_field_border_color',
        $formFieldBackgroundColor: 'settings.styles.colors.form_field_background_color',
        $tooltipBackgroundColor: 'settings.styles.colors.tooltip_background_color',
        $infoContainerBackgroundColor: 'settings.styles.colors.info_container_background_color'
      }
    },
    content: {
      sitewide: {
        $enabled: 'settings.config.siteWideMessageEnabled',
        $textColor: 'settings.styles.site_wide_info.text_color',
        $backgroundColor: 'settings.styles.site_wide_info.background_color',
        $message: 'settings.config.importantMessage'
      },
      orderLookup: {
        $image: 'settings.styles.order_lookup_image',
        $header: 'settings.strings.ORDER_LOOKUP.RETURN_AND_TRACK_ORDERS',
        $inputLabel: 'settings.strings.ORDER_LOOKUP.ENTER_ORDER_ID',
        $inputPlaceholder: 'settings.strings.ORDER_LOOKUP.ENTER_ORDER_ID_PLACEHOLDER',
        $buttonText: 'settings.strings.ORDER_LOOKUP.NEXT'
      },
      orderIdHelpModal: {
        $enabled: 'settings.config.orderIdHelpEnabled',
        $image1: 'settings.styles.order_id_help_modal.image1',
        $image2: 'settings.styles.order_id_help_modal.image2',
        $image3: 'settings.styles.order_id_help_modal.image3',
        $helpQuestion: 'settings.strings.ORDER_ID_HELP_MODAL.HELP_QUESTION',
        $header: 'settings.strings.ORDER_ID_HELP_MODAL.HEADER',
        $message: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE',
        $messageOptional1: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE_OPTIONAL_1',
        $messageOptional2: 'settings.strings.ORDER_ID_HELP_MODAL.MESSAGE_OPTIONAL_2'
      },
      howItWorkModal: {
        $header: 'settings.strings.HOW_IT_WORKS.HOW_IT_WORKS',
        $body: 'settings.strings.HOW_IT_WORKS.HOW_IT_WORKS_BODY'
      },
      ineligibleReturnsModal: {
        $returnWindowExpirationModalHeader: 'settings.strings.ORDER_DISPLAY.ITEM_RETURN_POLICY_TITLE',
        $returnWindowExpirationModalCopy: 'settings.strings.ORDER_DISPLAY.ITEM_RETURN_POLICY_DESC',
        $finalSaleModalHeader: 'settings.strings.ORDER_DISPLAY.FINAL_SALE_MODAL_HEADER',
        $finalSaleModalCopy: 'settings.strings.ORDER_DISPLAY.FINAL_SALE_MODAL_BODY',
        $thirdPartyModalHeader: 'settings.strings.ORDER_DISPLAY.THIRD_PARTY_MODAL_HEADER',
        $thirdPartyModalCopy: 'settings.strings.ORDER_DISPLAY.THIRD_PARTY_MODAL_BODY'
      },
      instantStoreCredit: {
        $headline: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD',
        $subcopy: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD_MESSAGE',
        $disclaimer: 'settings.strings.EXCHANGE_REFUND.INSTANT_GIFT_CARD_DISCLAIMER'
      },
      $returnSummaryShippingFeeLabel: 'settings.strings.RETURN_REVIEW.SHIPPING_FEE'
    }
  },
  settings: {
    accountInfo: {
      $retailerName: 'settings.policies.retailer_info.name',
      $retailerPhone: 'settings.config.customerSupportContact',
      $retailerEmail: 'settings.policies.retailer_info.customer_support_email',
      $retailerFromEmail: 'settings.policies.retailer_info.from_email',
      $portalUrlPrefix: 'settings.config.urlPrefix'
    },
    returns: {
      $dropOffsIsEnabled: 'settings.policies.return_methods.drop_offs_is_enabled',
      $dropOffsFlatFeeAmount: 'settings.policies.return_methods.drop_offs_flat_fee_amount',
      $dropOffsDistanceThreshold: 'settings.policies.return_methods.drop_offs_distance_threshold',
      $homePickupIsEnabled: 'settings.policies.return_methods.home_pickup_is_enabled',
      $homePickupFlatFeeAmount: 'settings.policies.return_methods.home_pickup_flat_fee_amount',
      $mailBackFlatFeeAmount: 'settings.policies.return_methods.mail_back_flat_fee_amount',
      $mailBackHasOnlyMethodOfferedFeeEnabled: 'settings.policies.return_methods.mail_back_has_only_method_offered_fee_enabled',
      $mailBackOnlyMethodOfferedFee: 'settings.policies.return_methods.mail_back_only_method_offered_fee',
      $mailBackPackingSlipExtraField: 'settings.policies.packing_slip.extra_item_identifier',
      $mailBackPackingSlipExtraFieldTitle: 'settings.policies.packing_slip.extra_item_identifier_title',
      $mailBackAddVariantsToTitlesEnabled: 'settings.policies.packing_slip.add_variants_to_titles',
      $insufficientRefundEnabled: 'settings.policies.feature_flags.suppress_pickups_for_insufficient_refund_enabled',
      $supportedSortClassifications: 'settings.policies.supported_sort_classifications',
      $giftReturnsAllowedEnabled: 'settings.config.giftReturnsAllowed'
    },
    repurchases: {
      $instantExchangesEnabled: 'settings.policies.exchanges.instant.enabled',
      $instantGiftCardsEnabled: 'settings.policies.exchanges.instant_gift_card.enabled',
      $standardExchangesEnabled: 'settings.policies.exchanges.standard.enabled'
    },
    shipping: {
      $addressName: 'settings.policies.ship_to_address.name',
      $addressCompany: 'settings.policies.ship_to_address.company',
      $addressStreet1: 'settings.policies.ship_to_address.street1',
      $addressStreet2: 'settings.policies.ship_to_address.street2',
      $addressCity: 'settings.policies.ship_to_address.city',
      $addressState: 'settings.policies.ship_to_address.state',
      $addressZipcode: 'settings.policies.ship_to_address.zip',
      $mailbackShippingAccounts: 'settings.policies.return_methods.mailback_shipping_accounts'
    }
  },
  developers: {
    $policiesJson: {
      __path: 'settings.policies',
      __checkEmptyValuesChangesEnabled: true
    },
    $stringsJson: {
      __path: 'settings.strings',
      __checkEmptyValuesChangesEnabled: true
    },
    $stylesJson: {
      __path: 'settings.styles',
      __checkEmptyValuesChangesEnabled: true
    },
    $configJson: {
      __path: 'settings.config',
      __checkEmptyValuesChangesEnabled: true
    }
  }
})
